import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../Components-ui/Loader";
import Layout from "../../Layout/Layout";
import { useAuthStore } from "../../../Store/AuthStore";
import DashboardCardComponent from "./DashboardCardComponent";
import DashboardAccordian from "./DashboardAccordian";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import PaidIcon from "@mui/icons-material/Paid";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import { useHistory } from "react-router-dom";
import {
  getOrderStatistics,
  getTransactionStatistics,
  getUserStatistics,
} from "./DashboardCard.service";

type Props = {};

const DashboardCard = (props: Props) => {
  const { tokens, permissions } = useAuthStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState([]);
  const [transactionData, setTransactionData] = useState<any>([]);
  const [orderData, setOrderData] = useState<any>([]);
  const [metalData, setMetalData] = useState<any>([]);

  // const [expanded, setExpanded] = useState(false);
  // const toggleExpanded = () => setExpanded((current) => !current);

  const [activeUser, setActiveUser] = useState("today");
  const [activeTransaction, setActiveTransaction] = useState("today");
  const [activeOrder, setActiveOrder] = useState("today");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [dateRange, setDateRange] = useState({
    customStart: "",
    customEnd: "",
  });

  const history = useHistory();
  const handleUserClick = (event: any) => {
    setActiveUser(event.target.id);
    fetchUserData(event.target.id);
  };
  const handleTransactionClick = (event: any) => {
    setActiveTransaction(event.target.id);
    fetchTransactionData(event.target.id);
  };
  const handleOrderClick = (event: any) => {
    setActiveOrder(event.target.id);
    fetchOrderData(event.target.id);
  };
  const redirectToOrder = (e: any, lab: string, iconKey: string) => {
    const status: any = lab.toUpperCase();
    const newStatus = status.replace(/ /g, "_");
    const encodedStatus = encodeURI(newStatus);
    const encodedInterval = encodeURI(activeOrder);

    const url = `/order?status=${encodedStatus}&interval=${encodedInterval}&iconkey=${iconKey}`;
    history.push(url);
    // history.push(`/order?status=${status}&interval=${activeOrder}`);
  };
  const redirectToTransaction = (e: any, lab: string, iconKey: string) => {
    const status: any = lab.toUpperCase();
    const newStatus = status.replace(/ /g, "_");
    const encodedStatus = encodeURI(newStatus);
    const encodedInterval = encodeURI(activeTransaction);
    // const encodedInterval = encodeURI(activeTransaction);
    const url = `/fund-management?status=${encodedStatus}&interval=${encodedInterval}`;

    history.push(url);
  };
  const redirectToUser = (e: any, lab: string, iconKey: string) => {
    const status: any = lab.toUpperCase();
    const newStatus = status.replace(/ /g, "_");
    const encodedStatus = encodeURI(newStatus);
    const encodedInterval = encodeURI(activeUser);
    const url = `/customers?status=${encodedStatus}&interval=${encodedInterval}`;
    history.push(url);
  };

  const fetchUserData = async (param: any) => {
    try {
      setLoading(true);
      const params = typeof param === 'string'
        ? { interval: param }
        : { customStart: param.customStart, customEnd: param.customEnd };
      let response = await getUserStatistics(params, tokens);
      if (response.status == 200 && response.data) {
        setUserData(response.data);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };
  const fetchTransactionData = async (param: any) => {
    try {
      setLoading(true);
      const params = typeof param === 'string'
        ? { interval: param }
        : { customStart: param.customStart, customEnd: param.customEnd };
      let response = await getTransactionStatistics(params, tokens);
      if (response.status == 200 && response.data) {
        setTransactionData(response.data);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };
  const fetchOrderData = async (param: any) => {
    try {
      setLoading(true);
      const params = typeof param === 'string'
        ? { interval: param }
        : { customStart: param.customStart, customEnd: param.customEnd };
      let response = await getOrderStatistics(params, tokens);
      if (response.status == 200 && response.data) {
        setOrderData(response.data);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };
  const handleDateChange = (e: any, type: any) => {
    setDateRange((prev) => ({
      ...prev,
      [type]: e.target.value,
    }));
  };

  const handleDateRangeSubmit = async () => {
    try {
      setIsModalOpen(false);

      if (activeUser === "date_range") {
        await fetchUserData(dateRange);
        setActiveTransaction('today');
        setActiveOrder('today');
      } else if (activeTransaction === "date_range") {
        await fetchTransactionData(dateRange);
        setActiveUser('today');
        setActiveOrder('today');
      } else if (activeOrder === "date_range") {
        await fetchOrderData(dateRange);
        setActiveUser('today');
        setActiveTransaction('today')
      } else {
        console.warn("No active entity matches 'date_range'");
      }
    } catch (error) {
      console.error("Error during date range submission:", error);
      toast.error("Something went wrong while fetching data.");
    }
  };

  const handleCancelClick = async () => {
    try {
      setIsModalOpen(false);

      if (activeUser === "date_range") {
        setActiveUser('today');
        setActiveTransaction('today');
        setActiveOrder('today');
        await fetchUserData('today');
      } else if (activeTransaction === "date_range") {
        setActiveTransaction('today');
        setActiveUser('today');
        setActiveOrder('today');
        await fetchTransactionData('today');
      } else if (activeOrder === "date_range") {
        setActiveOrder('today');
        setActiveUser('today');
        setActiveTransaction('today');
        await fetchOrderData('today');
      } else {
        console.warn("No active entity matches 'date_range'");
      }
    } catch (error) {
      console.error("Error during date range submission:", error);
      toast.error("Something went wrong while fetching data.");
    }

  }

  useEffect(() => {
    fetchUserData('today');
    fetchTransactionData('today');
    fetchOrderData('today');
  }, []);
  useEffect(() => {
    setMetalData(orderData.slice(-2));
  }, [orderData]);

  return (
    <>
      {loading && <Loader />}
      <ToastContainer />
      <Layout type="Reports">
        <div className="rounded  shadow-md mb-3 ">
          <fieldset className="border border-gray-300 flex bg-white rounded ">
            <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
              {"Users"}
            </legend>
            <div className="p-5 w-full ">
              {/* <div className="p-5 d-flex flex-column "> */}
              <div className="  flex mb-3">
                <button
                  style={{
                    backgroundColor: activeUser === "today" ? "#28a745" : "#420075",
                  }}
                  className={`
                  py-1 px-2  shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={'today'}
                  id={"today"}
                  onClick={(e) => handleUserClick(e)}
                >
                  Today
                </button>
                <button
                  style={{
                    backgroundColor: activeUser === "1" ? "#28a745" : "#420075",
                  }}
                  className={`
                  py-1 px-2  shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={1}
                  id={"1"}
                  onClick={(e) => handleUserClick(e)}
                >
                  Yesterday
                </button>
                <button
                  style={{
                    backgroundColor: activeUser === "7" ? "#28a745" : "#420075",
                  }}
                  className={`
                          py-1 px-2 shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={7}
                  id={"7"}
                  onClick={(e) => {
                    handleUserClick(e);
                  }}
                >
                  7 Days
                </button>
                <button
                  style={{
                    backgroundColor:
                      activeUser === "30" ? "#28a745" : "#420075",
                  }}
                  className={`
                          py-1 px-2 shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={30}
                  id={"30"}
                  onClick={(e) => handleUserClick(e)}
                >
                  30 Days
                </button>
                <button
                  style={{
                    backgroundColor:
                      activeUser === "MTD" ? "#28a745" : "#420075",
                  }}
                  className={`
                          py-1 px-2 shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={"MTD"}
                  id={"MTD"}
                  onClick={(e) => handleUserClick(e)}
                >
                    MTD
                </button>
                <button
                  style={{
                    backgroundColor: activeUser === "0" ? "#28a745" : "#420075",
                  }}
                  className={`
                          py-1 px-2 shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={0}
                  id={"0"}
                  onClick={(e) => handleUserClick(e)}
                >
                  All
                </button>
                <div className="flex items-center">
                  {/* Date Range Button */}
                  <button
                    onClick={() => {
                      setIsModalOpen(true);
                      setActiveUser("date_range");
                      setActiveOrder("");
                      setActiveTransaction("");

                    }}
                    className={`
                          py-1 px-2 shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}

                    style={{
                      backgroundColor: activeUser === "date_range" ? "#28a745" : "#420075",
                    }}
                  >
                    Date Range
                  </button>
                  <span className="text-sm text-gray-600">
                    {activeUser === "date_range" && dateRange.customStart && dateRange.customEnd
                      ? `Data From:  ${dateRange.customStart} - ${dateRange.customEnd}`
                      : ' '}
                  </span>

                </div>
              </div>
              <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
                {userData?.map((user: any) => (
                  <DashboardCardComponent
                    label={user.label}
                    value={user.value}
                    iconkey={user.key}
                    redirectFunc={redirectToUser}
                  />
                ))}
              </div>
            </div>
          </fieldset>
        </div>


        <div className="rounded   shadow-md mb-3">
          <fieldset className="border border-gray-300 flex bg-white rounded ">
            <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
              {"Orders"}
            </legend>

            <div className="p-5 w-full ">
              {/* <div
                className="float-right"
                style={{ cursor: "pointer" }}
                onClick={toggleExpanded}
              >
                {expanded ? "Show Less" : "Show More"}
                <ArrowDropDownIcon fontSize={"medium"} />
              </div> */}
              <div className=" select-none flex mb-3">
                <button
                  style={{
                    backgroundColor: activeOrder === "today" ? "#28a745" : "#420075",
                  }}
                  className={`
                  py-1 px-2  shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={'today'}
                  id={"today"}
                  onClick={(e) => handleOrderClick(e)}
                >
                  Today
                </button>
                <button
                  style={{
                    backgroundColor:
                      activeOrder === "1" ? "#28a745" : "#420075",
                  }}
                  className={`
                          py-1 px-2 shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={1}
                  id={"1"}
                  onClick={(e) => handleOrderClick(e)}
                >
                  Yesterday
                </button>
                <button
                  style={{
                    backgroundColor:
                      activeOrder === "7" ? "#28a745" : "#420075",
                  }}
                  className={`
                          py-1 px-2 shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={7}
                  id={"7"}
                  onClick={(e) => handleOrderClick(e)}
                >
                  7 Days
                </button>
                <button
                  style={{
                    backgroundColor:
                      activeOrder === "30" ? "#28a745" : "#420075",
                  }}
                  className={`
                          py-1 px-2 shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={30}
                  id={"30"}
                  onClick={(e) => handleOrderClick(e)}
                >
                  30 Days
                </button>
                <button
                  style={{
                    backgroundColor:
                      activeOrder === "MTD" ? "#28a745" : "#420075",
                  }}
                  className={`
                          py-1 px-2 shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={"MTD"}
                  id={"MTD"}
                  onClick={(e) => handleOrderClick(e)}
                >
                  MTD
                </button>
                <button
                  style={{
                    backgroundColor:
                      activeOrder === "0" ? "#28a745" : "#420075",
                  }}
                  className={`
                          py-1 px-2 shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={0}
                  id={"0"}
                  onClick={(e) => handleOrderClick(e)}
                >
                  All
                </button>
                <div className="flex items-center">
                  {/* Date Range Button */}
                  <button
                    onClick={() => {
                      setIsModalOpen(true);
                      setActiveOrder("date_range");
                      setActiveTransaction("");
                      setActiveUser("");
                    }}
                    className={`
                          py-1 px-2 shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}

                    style={{
                      backgroundColor: activeOrder === "date_range" ? "#28a745" : "#420075",
                    }}
                  >
                    Date Range
                  </button>
                  <span className="text-sm text-gray-600">
                    {activeOrder === "date_range" && dateRange.customStart && dateRange.customEnd
                      ? `Data From:  ${dateRange.customStart} - ${dateRange.customEnd}`
                      : ' '}
                  </span>
                </div>

              </div>

              <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-3">
                {orderData?.slice(0, -4).map((order: any) => (
                  <DashboardCardComponent
                    label={order.label}
                    value={order?.value}
                    iconkey={order.key}
                    amount={order?.amount}
                    // icon={<CheckCircleIcon />}
                    redirectFunc={redirectToOrder}
                  />
                ))}
              </div>
              {/* <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
                <DashboardCardComponent
                  label={orderData[0]?.label}
                  value={orderData[0]?.value}
                  icon={<CheckCircleIcon />}
                  redirectToOrder={redirectToOrder}
                />
                <DashboardCardComponent
                  label={orderData[1]?.label}
                  value={orderData[1]?.value}
                  icon={<CheckCircleIcon />}
                  redirectToOrder={redirectToOrder}
                />
                <DashboardCardComponent
                  label={orderData[2]?.label}
                  value={orderData[2]?.value}
                  icon={<ErrorIcon />}
                  redirectToOrder={redirectToOrder}
                />
                <DashboardCardComponent
                  label={orderData[3]?.label}
                  value={orderData[3]?.value}
                  icon={<CheckCircleIcon />}
                  redirectToOrder={redirectToOrder}
                />
              </div> */}
              <DashboardAccordian
                expanded={true}
                sellMetals={metalData[0]?.sellTransactions}
                buyMetals={metalData[1]?.buyBackTransactions}
                redirectFunc={redirectToOrder}
              />
              {/* Modal for Date Range Picker */}
              {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50  ">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-1-3">
                    <h3 className="text-xl mb-4">Select Date Range</h3>
                    <div className="flex items-center mb-4">
                      <input
                        aria-label="Start date"
                        type="date"
                        className="input-date mr-2 border border border-light"
                        value={dateRange.customStart}
                        onChange={(e) => handleDateChange(e, "customStart")}
                      />
                      <input
                        type="date"
                        className="input-date mr-2 border border border-light"
                        value={dateRange.customEnd}
                        onChange={(e) => handleDateChange(e, "customEnd")}
                      />
                    </div>
                    <div className="flex justify-end">
                      <button
                        // onClick={() => {
                        //   setIsModalOpen(false);
                        //   setActiveOrder('0');
                        //   setActiveTransaction('0');
                        //   setActiveUser('0');
                        // }}
                        onClick={handleCancelClick}
                        className="py-1 px-3 shadow-md no-underline text-white rounded-full btn-sm mr-2"
                        style={{ backgroundColor: "#dc3545" }}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleDateRangeSubmit}
                        className="py-1 px-3 shadow-md no-underline text-white rounded-full btn-sm"
                        style={{ backgroundColor: !dateRange.customStart || !dateRange.customEnd ? "rgba(40, 167, 69, 0.5)" : "#28a745" }}
                        disabled={!dateRange.customStart || !dateRange.customEnd}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </fieldset>
        </div>

        <div className="rounded   shadow-md mb-3">
          <fieldset className="border border-gray-300 flex bg-white rounded ">
            <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mb-1">
              {"Transactions"}
            </legend>
            <div className="p-5 w-full ">
              {/* <div className="p-5 d-flex flex-column "> */}

              <div className="  flex mb-3">
                <button
                  style={{
                    backgroundColor: activeTransaction === "today" ? "#28a745" : "#420075",
                  }}
                  className={`
                  py-1 px-2  shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={'today'}
                  id={"today"}
                  onClick={(e) => handleTransactionClick(e)}
                >
                  Today
                </button>
                <button
                  style={{
                    backgroundColor:
                      activeTransaction === "1" ? "#28a745" : "#420075",
                  }}
                  className={`
                  py-1 px-2  shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={1}
                  id={"1"}
                  onClick={(e) => handleTransactionClick(e)}
                >
                  Yesterday
                </button>
                <button
                  style={{
                    backgroundColor:
                      activeTransaction === "7" ? "#28a745" : "#420075",
                  }}
                  className={`
                          py-1 px-2 shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={7}
                  id={"7"}
                  onClick={(e) => {
                    handleTransactionClick(e);
                  }}
                >
                  7 Days
                </button>
                <button
                  style={{
                    backgroundColor:
                      activeTransaction === "30" ? "#28a745" : "#420075",
                  }}
                  className={`
                          py-1 px-2 shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={30}
                  id={"30"}
                  onClick={(e) => handleTransactionClick(e)}
                >
                  30 Days
                </button>
                <button
                  style={{
                    backgroundColor:
                      activeTransaction === "MTD" ? "#28a745" : "#420075",
                  }}
                  className={`
                          py-1 px-2 shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={"MTD"}
                  id={"MTD"}
                  onClick={(e) => handleTransactionClick(e)}
                >
                  MTD
                </button>
                <button
                  style={{
                    backgroundColor:
                      activeTransaction === "0" ? "#28a745" : "#420075",
                  }}
                  className={`
                          py-1 px-2 shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}
                  key={0}
                  id={"0"}
                  onClick={(e) => handleTransactionClick(e)}
                >
                  All
                </button>

                <div className="flex items-center">
                  {/* Date Range Button */}
                  <button
                    onClick={() => {
                      setIsModalOpen(true);
                      setActiveTransaction("date_range");
                      setActiveOrder("");
                      setActiveUser("");
                    }}
                    className={`
                          py-1 px-2 shadow-md no-underline text-white rounded-full font-sans font-semibold text-xs btn-sm focus:outline-none active:shadow-none mr-2`}

                    style={{
                      backgroundColor: activeTransaction === "date_range" ? "#28a745" : "#420075",
                    }}
                  >
                    Date Range
                  </button>
                  <span className="text-sm text-gray-600">
                    {activeTransaction === "date_range" && dateRange.customStart && dateRange.customEnd
                      ? `Data From:  ${dateRange.customStart} - ${dateRange.customEnd}`
                      : ' '}
                  </span>


                </div>

              </div>
              <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-3">
                {transactionData?.map((transaction: any) => (
                  <DashboardCardComponent
                    label={transaction.label}
                    value={transaction.value}
                    iconkey={transaction.key}
                    icon={<CurrencyRupeeIcon />}
                    redirectFunc={redirectToTransaction}
                  />
                ))}
              </div>
            </div>
          </fieldset>
        </div>
      </Layout>
    </>
  );
};

export default DashboardCard;
