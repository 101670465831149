import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useAuthStore } from "../../../Store/AuthStore";
import Layout from "../../Layout/Layout";
import { useParams } from "react-router-dom";
import CustomerService from "../Services/Customer.service";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import Loader from "../../../Components-ui/Loader";
import file from "../../../Images/file.png";
import { string } from "mathjs";
import SimpleReactValidator from "simple-react-validator";
import Modal from "../../../Components-ui/Modal/Modal";
import OtpInput from "react-otp-input";
import FileUpload from "react-material-file-upload";
import HasAnyAccess from "../../../Components-ui/hasAnyAccess";
import { Button } from "@mui/material";
import EditbankModal from "./EditbankModal";
import GainLossSummeryTable from "./GainLossSummeryTable";


const incomeGroups = {
  data: [
    { id: 1, title: "0 to 3 lakhs" },
    { id: 2, title: "3 to 7 lakhs" },
    { id: 3, title: "7 to 10 lakhs" },
    { id: 4, title: "10 to 12 lakhs" },
    { id: 5, title: "12 to 15 lakhs" },
    { id: 6, title: "15 lakhs and above" },
  ],
};

const incomeSources = {
  data: [
    { id: 1, title: "Salaried" },
    { id: 2, title: "Self-employed" },
  ],
};



export const SingleUSer: React.FC = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploadModalShow, setUploadModalShow] = useState<any>(false);
  const [metal, setMetal] = useState({});
  const [otpModalOpen, setOtpModalOpen] = useState<any>(false);
  const [indexNo, setIndex] = useState<any>(0);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [customerObj, setCustomer] = useState<any>([]);
  const [userObj, setUser] = useState<any>([]);
  const [accountObj, setAccount] = useState<any>([]);
  const [nomeineeDetails, setNomineeDetails] = useState<any>([]);
  const [metalsObj, setMetals] = useState<any>([]);
  const [metalHoldings, setMetalHoldings] = useState<any>([]);
  const [metalLedgerObj, setMetalLedger] = useState<any>([]);
  const [docs, setDocs] = useState<any>([]);
  const [message, setMessages] = useState({});
  const [otpResponse, setOtpResponse] = useState<any>({});
  const { tokens, userData } = useAuthStore();
  const history = useHistory();
  const [Loading, setLoading] = useState<any>(true);
  let [isOpen, setIsOpen] = useState(false);
  let [docId, setDocId] = useState<any>(string);

  let customer: any = useParams();
  const [show, setModalStatus] = useState<any>(false);
  const [children, setChildren] = useState<any>();
  const [otpChildren, setOtpChildren] = useState<any>();
  const [selectedAddress, setSelectedAddress] = useState<any>({});
  const [accountDetails, setAccountDetails] = useState<any>({});
  const [status, setStatus] = useState<any>("");
  const [validatorStatus, setValidator] = useState<boolean>(false);
  const [otp, setOtp] = useState<any>();
  const [canVerfy, setCanVerfy] = useState<any>(false);
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  const [resendStatus, setResendStatus] = useState<boolean>(false);
  const [resendSuccess, setResendSuccess] = useState<any>();
  const [realisedData, setRealisedData] = useState<any>();
  const [unRealisedData, setUnRealisedData] = useState<any>();
  const [realisedLoading, setRealisedLoading] = useState<boolean>(false);


  const handleChange = (data: any) => {
    setOtp(data);
    data.length === 6 ? setCanVerfy(true) : setCanVerfy(false);
  };
  const simpleValidator = new SimpleReactValidator({
    locale: "en",
    validators: {},
  });
  const validationRef = React.useRef(simpleValidator);
  const validator = validationRef.current;

  const fileChanged = (data: any, index: any) => {
    setIndex(data.docTypeId);
    setUploadModalShow(true);
    setSelectedFile({
      ...selectedFile,
      data,
    });
  };

  const fileUpload = async () => {
    if (files.length > 0) {
      setOtpModalOpen(true);
      if (!resendStatus) {
        otpSend();
      }
    } else {
      toast.error("Choose file to upload!");
    }
  };

  const otpSend = () => {
    setOtpResponse({});
    var data = {
      customerId: customer.id,
      reference: "MANUAL_DOC_UPLOAD",
    };
    CustomerService.getOtp(data, tokens)
      .then(async (response: any) => {
        setOtpResponse(response.data.data);
        toast.success("OTP has been send.");
        setResendSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.data.errors[0].title);
        setResendSuccess(false);
      });
  };

  const uploadDoc = () => {
    const formData = new FormData();
    formData.append("file", files[0], files[0].name);
    formData.append("docTypeId", selectedFile.data.docTypeId);
    formData.append("customerId", customer.id);

    CustomerService.fileUpload(formData, tokens)
      .then(async (response: any) => {
        const c_doc = response.data.data.docs.filter(
          (doc: any) => doc.docTypeId === indexNo
        );
        setDocId(c_doc[0].file.docUploadId);
        setUploadModalShow(false);
        setOtpModalOpen(false);
        set_verification(c_doc[0].file.docUploadId);
        toast.success("Document Uploaded Successfully");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.data.errors[0].title);
      });
  };

  const verifyOtp = () => {
    var data = {
      otp: otp,
      otpId: otpResponse?.otpId,
    };
    CustomerService.verifyOtp(data, tokens)
      .then(async (response: any) => {
        uploadDoc();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.data.errors[0].title);
      });
  };

  // Realixed and Booked Gain Loss Table API
  const profitLoss = async () => {

    try {
      const data = { customerId: customer?.id };
      setRealisedLoading(true);
      const res = await CustomerService.realisedData(
        data,
        userObj?.userId,
        userObj?.partner?.id,
        tokens,
      );
      if (res.status == 200) {
        const newdata: Object = res.data.data.Realised;
        // arrayOfObjects = Object.entries(newdata).map(([key, value]) => ({
        //   metal: key,
        //   ...value,
        // }));
        setRealisedData(res?.data?.data?.Realised);
        setUnRealisedData(res?.data?.data?.Unrealised);
        setRealisedLoading(false);

        // console.log('realiseddata', arrayOfObjects);
      } else {
        // setRealisedLoading(false);

        toast.error('Something went wrong');
      }
    } catch (err) {
      console.log(err);
      setRealisedLoading(false);

      toast.error('Something went wrong');
    }
  };


  //edit bank details

  const [openBankModal, setOpenBankModal] = useState<any>(false);
  const closeBankModal = () => {
    setOpenBankModal(false);
  };
  //end edit bank details
  const hideModal = () => {
    setModalStatus(false);
    setOtpModalOpen(false);
    setUploadModalShow(false);
    setFiles([]);
  };

  useEffect(() => {
    childrenElements();
  }, [selectedAddress, status]);

  useEffect(() => {
    otpChildElements();
  }, [otpModalOpen]);

  useEffect(() => {
    otpChildElements();
  }, [otp]);

  useEffect(() => {
    otpChildElements();
  }, [otpResponse]);

  useEffect(() => {
    fileUploadChildren();
    setResendStatus(false);
    setResendSuccess(false);
    setMinutes(2);
    setSeconds(0);
  }, [uploadModalShow]);

  useEffect(() => {
    fileUploadChildren();
  }, [files]);

  useEffect(() => {
    otpChildElements();
  }, [seconds]);

  useEffect(() => {
    otpChildElements();
  }, [resendStatus]);

  useEffect(() => {
    otpChildElements();
  }, [resendSuccess]);

  useEffect(() => {
    if (resendStatus) {
      const myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
            setResendStatus(false);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [resendStatus, seconds]);

  useEffect(() => {
    if (userObj?.userId?.length > 0) {
      profitLoss();
    }
  }, [userObj])

  const getValidatorError = (nameOfStateProp: any) => {
    const allErrorMessages = validator.getErrorMessages();
    return allErrorMessages[nameOfStateProp];
  };

  const Save = async () => {
    var data;
    setValidator(true);
    if (validator.allValid()) {
      if ("BILLING" === status) {
        accountDetails.billingAddress = selectedAddress;
        data = {
          accountId: accountDetails.accountId,
          billingAddress: selectedAddress,
        };
      } else {
        accountDetails.shippingAddress = selectedAddress;
        data = {
          accountId: accountDetails.accountId,
          shippingAddress: selectedAddress,
        };
      }
      await CustomerService.updateAccount(
        accountDetails.accountId,
        data,
        tokens
      )
        .then((response: any) => {
          toast.success("Record updated Successfully");
          setModalStatus(false);
          setAccountDetails({});
          setSelectedAddress({});
          get_customer();
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.errors[0].title);
        });
      setValidator(false);
    }
  };

  const mappedIncomeGroups = (id: any) => {
    const group = incomeGroups.data.find((range) => range?.id == id);
    return group ? group.title : "N/A";
  };

  const MappedIncomeSources = (id: any) => {
    const source = incomeSources.data.find((item) => item?.id == id);
    return source ? source.title : "N/A";
  }



  const childrenElements = () => {
    setChildren(
      <div className="w-full max-w-xs">
        <h1 className="font-bold">
          {"BILLING" === status
            ? "Edit Billing Address"
            : "Edit Shipping Address"}
        </h1>
        <h6>Account ID : {accountDetails.accountId || ""}</h6>
        <form className="bg-white rounded pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              {"BILLING" === status ? "Billing Address" : "Shipping Address"}
            </label>
            <textarea
              rows={3}
              id="about"
              name="about"
              className="border p-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder={
                "BILLING" === status ? "Billing Address" : "Shipping Address"
              }
              onChange={(event) =>
                setSelectedAddress({
                  ...selectedAddress,
                  address1: event.target.value,
                })
              }
              value={selectedAddress.address1 || ""}
            />
            {validator.message(
              "address1",
              selectedAddress.address1,
              "required"
            )}
            {validatorStatus ? (
              <span className="text-xs text-red-700" id="name">
                {getValidatorError("address1")}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              City
            </label>
            <input
              className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
              id="email"
              type="email"
              name="email"
              placeholder="City"
              onChange={(event) =>
                setSelectedAddress({
                  ...selectedAddress,
                  city: event.target.value,
                })
              }
              value={selectedAddress.city || ""}
              autoComplete="off"
            />
            {validator.message("city", selectedAddress.city, "required")}
            {validatorStatus ? (
              <span className="text-xs text-red-700" id="name">
                {getValidatorError("city")}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              State
            </label>
            <input
              className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
              id="email"
              type="email"
              name="email"
              placeholder="State"
              onChange={(event) =>
                setSelectedAddress({
                  ...selectedAddress,
                  state: event.target.value,
                })
              }
              value={selectedAddress.state || ""}
              autoComplete="off"
            />
            {validator.message("state", selectedAddress.state, "required")}
            {validatorStatus ? (
              <span className="text-xs text-red-700" id="name">
                {getValidatorError("state")}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Country Code
            </label>
            <input
              className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
              id="email"
              type="email"
              name="email"
              placeholder="Country Code"
              onChange={(event) =>
                setSelectedAddress({
                  ...selectedAddress,
                  countryCode: event.target.value,
                })
              }
              value={selectedAddress.countryCode || ""}
              autoComplete="off"
            />
            {validator.message(
              "countryCode",
              selectedAddress.countryCode,
              "required"
            )}
            {validatorStatus ? (
              <span className="text-xs text-red-700" id="name">
                {getValidatorError("countryCode")}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Pin Code
            </label>
            <input
              className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
              id="email"
              type="email"
              name="email"
              placeholder="Pin Code"
              onChange={(event) =>
                setSelectedAddress({
                  ...selectedAddress,
                  pinCode: event.target.value,
                })
              }
              value={selectedAddress.pinCode || ""}
              autoComplete="off"
            />
            {validator.message("pinCode", selectedAddress.pinCode, "required")}
            {validatorStatus ? (
              <span className="text-xs text-red-700" id="name">
                {getValidatorError("pinCode")}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Address Type
            </label>
            <select
              className="rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4 border"
              id="grid-partner"
              onChange={(event) =>
                setSelectedAddress({
                  ...selectedAddress,
                  addressType: event.target.value,
                })
              }
              value={selectedAddress.addressType || ""}
            >
              <option value="">Select an Address Type</option>
              <option value="HOME">HOME</option>
              <option value="BUSINESS">BUSINESS</option>
            </select>
            {validator.message(
              "addressType",
              selectedAddress.addressType,
              "required"
            )}
            {validatorStatus ? (
              <span className="text-xs text-red-700" id="name">
                {getValidatorError("addressType")}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="flex items-center justify-between flex-row-reverse">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => {
                Save();
              }}
            >
              {"ADD" === status ? "SAVE" : "UPDATE"}
            </button>
          </div>
        </form>
      </div>
    );
  };

  const fileUploadChildren = () => {
    setChildren(
      <div className="w-full">
        <form className="bg-white rounded pt-6 mb-2">
          <div className="mb-4">
            <FileUpload
              accept={["application/pdf", "image/jpg", "image/jpeg"]}
              title={"Drag and drop file to here to upload."}
              maxFiles={1}
              multiple={false}
              buttonText={"Choose File"}
              value={files}
              onChange={setFiles}
            />
          </div>
          <div className="flex items-center justify-between flex-row-reverse">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => {
                fileUpload();
              }}
            >
              Upload
            </button>
          </div>
        </form>
      </div>
    );
  };

  const otpChildElements = () => {
    setOtpChildren(
      <div className="w-full">
        <h1 className="font-bold text-center">
          {"Enter the OTP sent your registered mobile number"}
        </h1>
        <form className="bg-white rounded pb-8">
          <div className="">
            <OtpInput
              inputStyle={{
                width: "2.7rem",
                height: "2.7rem",
                margin: "10px 0.4rem",
                fontSize: "2rem",
                borderRadius: 4,
                border: "2px solid rgba(0,0,0,0.3)",
              }}
              containerStyle={{ justifyContent: "center" }}
              value={otp}
              onChange={handleChange}
              isInputNum={true}
              isInputSecure={true}
              numInputs={6}
              shouldAutoFocus
            />
          </div>
          <div
            className="flex justify-evenly text-center mb-2"
            style={{ fontSize: "12px" }}
          >
            <div>
              <span>Didn't you receive the OTP? </span>
              <button
                disabled={resendStatus}
                className="outline-none focus:outline-none text-red-400"
                type="button"
                style={{ fontSize: "12px", cursor: "pointer" }}
                onClick={() => {
                  setResendStatus(true);
                  otpSend();
                }}
              >
                Resend OTP
              </button>
            </div>
            {!resendStatus ? null : (
              <h5 className="float-end">
                {" "}
                {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
              </h5>
            )}
          </div>
          {resendSuccess ? (
            <div className="mb-2 text-center" style={{ fontSize: "12px" }}>
              <span className="text-green-500">Successfully send the OTP.</span>
            </div>
          ) : (
            <div className="mb-2 text-center" style={{ fontSize: "12px" }}>
              <span className="text-red-600">
                There was an error sending the OTP.
              </span>
            </div>
          )}
          <div className="flex items-center flex-row-reverse">
            <button
              disabled={!canVerfy}
              className="ml-1.5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => {
                verifyOtp();
              }}
            >
              {"Verify"}
            </button>
            <button
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => {
                setOtpModalOpen(false);
              }}
            >
              {"Close"}
            </button>
          </div>
        </form>
      </div>
    );
  };

  const editModalElements = (account: any, type: any) => {
    setStatus(type);
    childrenElements();
    setAccountDetails(account);
    setSelectedAddress(
      "BILLING" === type ? account.billingAddress : account.shippingAddress
    );
    setModalStatus(true);
  };

  const get_customer = async () => {
    await CustomerService.getCustomer(customer.id, tokens, userData?.sub)
      .then((response: any) => {
        setCustomer(response.data.data.customer);
        setAccount(response.data.data.customer.accounts);
        setUser(response.data.data.user);
        setNomineeDetails(response.data.data.customer.nomineeDetails)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.data.errors[0].title);
      });
  };

  const get_metals = async () => {
    await CustomerService.getMetals()
      .then((response: any) => {
        setMetals(response.data.data.result);
        get_metal_ledger(response.data.data.result);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.errors[0].title);
      });
  };

  const get_metal_ledger = async (metals: any) => {
    await CustomerService.getMetalLedger(customer.id)
      .then((response: any) => {
        const summary = response.data.data.summary.filter(
          (obj: any) => obj.type === "VAULT"
        )[0];

        const res = metals.map((metal_: any) => {
          return {
            ...metal_,
            summary:
              summary.data.length > 0
                ? summary.data.filter(
                  (obj: any) => obj.metal === metal_.metalCode
                )
                : [],
          };
        });

        setMetalLedger(response.data.data);
        setMetalHoldings(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const get_docs = async () => {
    await CustomerService.getDocs(customer.id)
      .then((response: any) => {
        setDocs(response.data.data.docs);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.data.errors[0].title);
      });
  };

  const set_verification = async (id: any) => {
    await CustomerService.setVerification(id, tokens)
      .then((response: any) => {
        setLoading(true);
        get_metals();
        get_customer();
        get_docs();
        setUploadModalShow(false);
        setDocId(null);
        setSelectedFile(null);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
        setDocId(null);
      });
  };

  function closeModal() {
    setIsOpen(false);
    setDocId(null);
  }

  function openModal(docId: string) {
    setDocId(docId);
    setIsOpen(true);
  }

  useEffect(() => {
    setLoading(true);
    get_metals();
    get_customer();
    get_docs();
  }, []);

  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }, [show]);

  var user: any = useParams();

  const imageOpen = (doc: any) => {
    setModalStatus(true);
    setChildren(
      <div className="w-full">
        <h1 className="font-bold">{doc.description}</h1>
        <div
          style={{ pointerEvents: "none" }}
          className="bg-white rounded pt-6 pb-8 mb-4"
        >
          <img
            onContextMenu={(e) => e.preventDefault()}
            style={{ pointerEvents: "none" }}
            src={process.env.REACT_APP_URL + "/" + doc.file.resource}
            alt=""
          />
        </div>
      </div>
    );
  };

  const changeStatus = async (status: any) => {
    let newObj = { ...customerObj };
    newObj.requiredStatus = "ENABLED" !== status ? "ENABLED" : "DISABLED";
    setCustomer(newObj);
    updateCustomer(newObj);
  };

  const updateCustomer = async (data: any) => {
    CustomerService.updateCustomer(customerObj.customerId, data, tokens)
      .then((response: any) => {
        get_customer();
        toast.success("Updated Successfully");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  return (
    <Layout type={"UserModule"}>
      <ToastContainer />
      {Loading ? (
        <Loader />
      ) : (
        <button
          onClick={() => {
            history.goBack();
          }}
          className="-mr-2 flex items-center rounded text-white font-bold text-xs uppercase"
        >
          <svg
            className="w-7"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
          >
            <path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" />
          </svg>{" "}
          {/* <span className="text-black">Back</span> */}
        </button>
      )}
      <div className="title flex items-center mb-5">
        {/* <svg
            className="w-14 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            />
          </svg> */}
        <div className="text">
          <h1 className="text-3xl font-bold flex items-center tracking-tight">
            {" "}
            Customer Profile
          </h1>
          <p className="">A detailed description of your selected customer.</p>
        </div>
      </div>
      <div className="contents mx-auto my-5 p-5">
        <div className="md:flex no-wrap md:-mx-2 ">
          <div className="w-full md:w-3/12 md:mx-2">
            {/* Customer Information */}
            <div className="bg-white p-3 border-t-4 border-purple-950">
              <div className="image overflow-hidden">
                {/* <img className="h-auto w-full mx-auto"
                            src="https://lavinephotography.com.au/wp-content/uploads/2017/01/PROFILE-Photography-112.jpg"
                            alt=""> */}
              </div>
              <h1 className="text-gray-900 font-bold text-xl leading-8 my-1">
                {customerObj.displayName}
              </h1>
              <h3 className="text-gray-600 font-lg text-semibold leading-6">
                {customerObj.partner ? customerObj.partner.name : ""}
              </h3>
              {/* <p className="text-sm text-gray-500 hover:text-gray-600 leading-6">Lorem ipsum dolor sit amet
                        consectetur adipisicing elit.
                        Reprehenderit, eligendi dolorum sequi illum qui unde aspernatur non deserunt</p> */}
              <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm text-xs">
                <li className="flex items-center py-3">
                  <span>ID</span>
                  <span className="ml-auto">{customerObj.customerId}</span>
                </li>
                <li className="flex items-center py-3">
                  <span>Type</span>
                  <span className="ml-auto">{customerObj.customerType}</span>
                </li>
                <li className="flex items-center py-3">
                  <span>Category</span>
                  <span className="ml-auto">
                    {customerObj.customerCategory}
                  </span>
                </li>
                <li className="flex items-center py-3">
                  <span>Status</span>
                  <span className="ml-auto">
                    <span
                      className={`${"ENABLED" === customerObj.active
                        ? "bg-green-500"
                        : "bg-red-500"
                        }  py-1 px-2 rounded text-white text-sm`}
                    >
                      {customerObj.active}
                    </span>
                  </span>
                </li>
                <li className="justify-between flex items-center py-3">
                  <span>Verify Status</span>
                  {HasAnyAccess(["customer.list.edit"]) && (
                    <td className="text-left relative">
                      <div
                        className="flex justify-between items-center"
                        onClick={() => {
                          changeStatus(customerObj.requiredStatus);
                        }}
                      >
                        <div
                          className={`w-12 flex items-center bg-gray-300 rounded-full px-0.5 p-1 duration-300 ease-in-out ${"ENABLED" === customerObj.requiredStatus
                            ? "bg-green-400"
                            : ""
                            }`}
                        >
                          <div
                            className={`bg-white w-5 h-5 rounded-full shadow-md transform duration-300 ease-in-out ${"ENABLED" === customerObj.requiredStatus
                              ? "translate-x-6"
                              : ""
                              }`}
                          ></div>
                        </div>
                      </div>
                    </td>
                  )}
                </li>
                <li className="flex items-center py-3">
                  <span>Member Since</span>
                  <span className="ml-auto">
                    {moment(customerObj.createdAt).format("MMM DD, YYYY")}
                  </span>
                </li>
              </ul>

              <fieldset className="bg-gray-100 rounded mt-3">
                <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mt-1">
                  Authorized User
                </legend>
                <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 divide-y rounded shadow-sm text-xs">
                  <li className="flex items-center py-3">
                    <span>User Type</span>
                    <span className="ml-auto">{userObj.userType}</span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>Email</span>
                    <span className="ml-auto">
                      <span
                        className={`${userObj.email && userObj.email.isVerified
                          ? "bg-green-500"
                          : "bg-red-500"
                          }  py-1 px-2 rounded text-white text-xs`}
                      >
                        {userObj.email ? userObj.email.value : ""}
                      </span>
                    </span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>Mobile</span>
                    <span className="ml-auto">
                      <span
                        className={`${userObj.mobile && userObj.mobile.isVerified
                          ? "bg-green-500"
                          : "bg-red-500"
                          }  py-1 px-2 rounded text-white text-xs`}
                      >
                        {userObj.mobile ? userObj.mobile.value : ""}
                      </span>
                    </span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>PAN</span>
                    <span className="ml-auto">
                      {customerObj.kyc?.pan?.data && (
                        <span
                          className={`${customerObj.kyc?.pan?.data &&
                            customerObj.kyc?.pan?.isVerified
                            ? "bg-green-500"
                            : "bg-red-500"
                            }  py-1 px-2 rounded text-white text-xs`}
                        >
                          {customerObj.kyc?.pan?.data?.pan
                            ? customerObj.kyc?.pan?.data?.pan
                            : ""}
                        </span>
                      )}
                    </span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>Aadhar</span>
                    <span className="ml-auto">
                      {customerObj.kyc?.aadhaar?.data && (
                        <span
                          className={`${customerObj.kyc?.aadhaar?.data &&
                            customerObj.kyc?.aadhaar?.isVerified
                            ? "bg-green-500"
                            : "bg-red-500"
                            }  py-1 px-2 rounded text-white text-xs`}
                        >
                          {customerObj.kyc?.aadhaar?.data?.maskedAadhaarNumber
                            ? customerObj.kyc?.aadhaar?.data
                              ?.maskedAadhaarNumber
                            : ""}
                        </span>
                      )}
                    </span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>GSTIN</span>
                    <span className="ml-auto">
                      <span
                        className={`py-1 px-2 rounded text-white text-xs ${customerObj?.companyGSTIN ? "bg-green-500" : "bg-red-500"
                          }`}
                      >
                        {customerObj?.companyGSTIN ? "YES" : "NO"}
                      </span>
                    </span>

                  </li>
                  <li className="flex items-center py-3">
                    <span>DOB</span>
                    <span className="ml-auto">{customerObj.dob ? moment(customerObj.dob).format("DD-MM-YYYY") : "N/A"}</span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>GENDER</span>
                    <span className="ml-auto">{customerObj.gender
                      ? customerObj.gender === "M"
                        ? "Male"
                        : "Female"
                      : "N/A"}
                    </span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>INCOME SOURCE</span>
                    <span className="ml-auto">
                      {customerObj.incomeSource ? MappedIncomeSources(customerObj.incomeSource) : "N/A"}
                    </span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>INCOME GROUP</span>
                    <span className="ml-auto">
                      {customerObj.incomeGroup ? mappedIncomeGroups(customerObj.incomeGroup) : "N/A"}
                    </span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>Created By</span>
                    <span className="ml-auto">{userObj.createdBy}</span>
                  </li>
                  <li className="flex items-center py-3">
                    <button
                      className="block w-full text-blue-800 text-sm font-semibold rounded-lg hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs"
                      onClick={() => history.push(`/users/${userObj.userId}`)}
                    >
                      {`more info >>`}
                    </button>
                  </li>
                </ul>
              </fieldset>

              <fieldset className="bg-gray-100 rounded mt-3">
                <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mt-1">
                  Nominee Details
                </legend>
                <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 divide-y rounded shadow-sm text-xs">
                  <li className="flex items-center py-3">
                    <span>Name</span>
                    <span className="ml-auto">{nomeineeDetails[0]?.firstName} {' '} {nomeineeDetails[0]?.lastName}</span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>Email</span>
                    <span className="ml-auto">
                      <span
                        className={`${nomeineeDetails[0]?.email && nomeineeDetails[0]?.isEmailVerified
                          ? "bg-green-500"
                          : "bg-red-500"
                          }  py-1 px-2 rounded text-white text-xs`}
                      >
                        {nomeineeDetails[0]?.email ? nomeineeDetails[0]?.email : ""}
                      </span>
                    </span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>Mobile</span>
                    <span className="ml-auto">
                      <span
                        className={`${nomeineeDetails[0]?.mobileNo && nomeineeDetails[0]?.isMobileVerified
                          ? "bg-green-500"
                          : "bg-red-500"
                          }  py-1 px-2 rounded text-white text-xs`}
                      >
                        {nomeineeDetails[0]?.mobileNo ? nomeineeDetails[0]?.mobileNo : ""}
                      </span>
                    </span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>Relation</span>
                    <span className="ml-auto">{nomeineeDetails[0]?.relation ? nomeineeDetails[0]?.relation : "N/A"}</span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>Age</span>
                    <span className="ml-auto">{nomeineeDetails[0]?.age ? nomeineeDetails[0]?.age : "N/A"}</span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>Last Updated</span>
                    <span className="ml-auto">{nomeineeDetails[0]?.updatedAt ? nomeineeDetails[0]?.updatedAt?.split('T')[0] : "N/A"}</span>
                  </li>
                </ul>
              </fieldset>

              {/* new */}
              {/* <fieldset className="bg-gray-100 rounded mt-3">
                <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mt-1">
                  User Status
                </legend>
                <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 divide-y rounded shadow-sm text-xs">
                  <li className="flex items-center py-3">
                    <span>Current status</span>
                    <div style={{display:'flex',marginLeft:'10px',flexDirection:'column'}}>
                      <span className="ml-auto">{"Force Password"}</span>
                      
                      <span className="ml-auto mt-0.5">
                        <span
                        
                          className={`${
                            customerObj.upiDetails &&
                            "VERIFIED" === customerObj.upiDetails.state
                              ? "bg-green-500"
                              : "bg-red-500"
                          }  py-1 px-1 mt-6 rounded text-white cursor-pointer`}
                        >
                          {'Sent Password'}
                        </span>
                      </span>
                    </div>
                  </li>
                </ul>
              </fieldset> */}
            </div>
            {/* Customer Information */}
            <div className="my-4"></div>
            {/* Payment Information */}
            <div className="bg-white p-3">
              <div className="flex items-center space-x-3 font-semibold text-gray-900 text-m leading-8">
                <span className="border-purple-950">
                  <svg
                    className="h-5 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </span>
                <span>Payment Information</span>
              </div>
              <fieldset className="bg-gray-100 rounded mt-3">
                <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mt-1">
                  UPI
                </legend>
                <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm text-xs">
                  <li className="flex items-center py-3">
                    <span>Handler</span>
                    <span className="ml-auto">
                      {customerObj.upiDetails &&
                        customerObj.upiDetails.accountNo
                        ? customerObj.upiDetails.accountNo
                        : "N/A"}
                    </span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>Name</span>
                    <span className="ml-auto">
                      {customerObj.upiDetails && customerObj.upiDetails.name
                        ? customerObj.upiDetails.name
                        : "N/A"}
                    </span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>Verification Status</span>
                    <span className="ml-auto">
                      <span
                        className={`${customerObj.upiDetails &&
                          "VERIFIED" === customerObj.upiDetails.state
                          ? "bg-green-500"
                          : "bg-red-500"
                          }  py-1 px-2 rounded text-white`}
                      >
                        {customerObj.upiDetails && customerObj.upiDetails.state
                          ? customerObj.upiDetails.state
                          : "N/A"}
                      </span>
                    </span>
                  </li>
                </ul>
              </fieldset>
              <fieldset className="bg-gray-100 rounded mt-3">
                <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mt-1">
                  Bank Account
                </legend>
                <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm text-xs">
                  <li className="flex items-center py-3">
                    <span>Account Number</span>
                    <span className="ml-auto">
                      {customerObj.bankDetails &&
                        customerObj.bankDetails.accountNo
                        ? customerObj.bankDetails.accountNo
                        : "N/A"}
                    </span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>Name</span>
                    <span className="ml-auto">
                      {customerObj.bankDetails && customerObj.bankDetails.name
                        ? customerObj.bankDetails.name
                        : "N/A"}
                    </span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>Bank</span>
                    <span className="ml-auto">
                      {customerObj.bankDetails && customerObj.bankDetails.bank
                        ? customerObj.bankDetails.bank
                        : "N/A"}
                    </span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>IFSC</span>
                    <span className="ml-auto">
                      {customerObj.bankDetails && customerObj.bankDetails.ifsc
                        ? customerObj.bankDetails.ifsc
                        : "N/A"}
                    </span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>Verification Status</span>
                    <span className="ml-auto">
                      <span
                        className={`${customerObj.bankDetails &&
                          "VERIFIED" === customerObj.bankDetails.state
                          ? "bg-green-500"
                          : "bg-red-500"
                          }  py-1 px-2 rounded text-white`}
                      >
                        {customerObj.bankDetails &&
                          customerObj.bankDetails.state
                          ? customerObj.bankDetails.state
                          : "N/A"}
                      </span>
                    </span>
                  </li>
                  {HasAnyAccess(["customer.list.edit"]) &&
                    customerObj.bankDetails &&
                    customerObj.bankDetails.accountNo && (
                      <li className="flex items-center py-3">
                        <span>Action</span>

                        <span className="ml-auto">
                          {" "}
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenBankModal(true)}
                          >
                            Edit
                          </Button>
                        </span>
                      </li>
                    )}
                </ul>
              </fieldset>
            </div>
          </div>
          {/* Payment Information */}
          <div className="w-full md:w-9/12 mx-2 h-64">
            {/* Customer holdings */}
            <div className="bg-white p-3 shadow-sm rounded-sm">
              <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                <span className="border-purple-950">
                  <svg
                    className="h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                </span>
                <span className="tracking-wide">Customer holdings</span>
              </div>
              <div className="text-gray-700 mt-5">
                <div className="flex flex-wrap justify-center -mx-4">
                  {metalHoldings.map((metal: any, index: number) => {
                    return (
                      <div className="w-full md:w-1/2 lg:w-1/4 px-4">
                        <div className="bg-white rounded-xl relative z-10 overflow-hidden border border-primary border-opacity-20 shadow-pricing py-4 px-2 mb-10">
                          <span className="text-primary font-semibold text-md block mb-4">
                            {metal.description}
                          </span>
                          <h2 className="font-bold text-dark text-[42px]">
                            {metal.summary.length > 0
                              ? metal.summary[0].metalBalance
                              : 0}
                            <span className="text-base text-body-color font-medium">
                              / {metal.uom}
                            </span>
                          </h2>

                          <div>
                            <span className="absolute right-0 top-7 z-[-1]">
                              <svg
                                width="77"
                                height="172"
                                viewBox="0 0 77 172"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="86"
                                  cy="86"
                                  r="86"
                                  fill="url(#paint0_linear)"
                                />
                                <defs>
                                  <linearGradient
                                    id="paint0_linear"
                                    x1="86"
                                    y1="0"
                                    x2="86"
                                    y2="172"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop
                                      stopColor="#301250"
                                      stopOpacity="0.09"
                                    />
                                    <stop
                                      offset="1"
                                      stopColor="#C4C4C4"
                                      stopOpacity="0"
                                    />
                                  </linearGradient>
                                </defs>
                              </svg>
                            </span>
                            <span className="absolute right-4 top-4 z-[-1]">
                              <svg
                                width="41"
                                height="89"
                                viewBox="0 0 41 89"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="38.9138"
                                  cy="87.4849"
                                  r="1.42021"
                                  transform="rotate(180 38.9138 87.4849)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="38.9138"
                                  cy="74.9871"
                                  r="1.42021"
                                  transform="rotate(180 38.9138 74.9871)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="38.9138"
                                  cy="62.4892"
                                  r="1.42021"
                                  transform="rotate(180 38.9138 62.4892)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="38.9138"
                                  cy="38.3457"
                                  r="1.42021"
                                  transform="rotate(180 38.9138 38.3457)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="38.9138"
                                  cy="13.634"
                                  r="1.42021"
                                  transform="rotate(180 38.9138 13.634)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="38.9138"
                                  cy="50.2754"
                                  r="1.42021"
                                  transform="rotate(180 38.9138 50.2754)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="38.9138"
                                  cy="26.1319"
                                  r="1.42021"
                                  transform="rotate(180 38.9138 26.1319)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="38.9138"
                                  cy="1.42021"
                                  r="1.42021"
                                  transform="rotate(180 38.9138 1.42021)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="26.4157"
                                  cy="87.4849"
                                  r="1.42021"
                                  transform="rotate(180 26.4157 87.4849)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="26.4157"
                                  cy="74.9871"
                                  r="1.42021"
                                  transform="rotate(180 26.4157 74.9871)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="26.4157"
                                  cy="62.4892"
                                  r="1.42021"
                                  transform="rotate(180 26.4157 62.4892)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="26.4157"
                                  cy="38.3457"
                                  r="1.42021"
                                  transform="rotate(180 26.4157 38.3457)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="26.4157"
                                  cy="13.634"
                                  r="1.42021"
                                  transform="rotate(180 26.4157 13.634)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="26.4157"
                                  cy="50.2754"
                                  r="1.42021"
                                  transform="rotate(180 26.4157 50.2754)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="26.4157"
                                  cy="26.1319"
                                  r="1.42021"
                                  transform="rotate(180 26.4157 26.1319)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="26.4157"
                                  cy="1.4202"
                                  r="1.42021"
                                  transform="rotate(180 26.4157 1.4202)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="13.9177"
                                  cy="87.4849"
                                  r="1.42021"
                                  transform="rotate(180 13.9177 87.4849)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="13.9177"
                                  cy="74.9871"
                                  r="1.42021"
                                  transform="rotate(180 13.9177 74.9871)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="13.9177"
                                  cy="62.4892"
                                  r="1.42021"
                                  transform="rotate(180 13.9177 62.4892)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="13.9177"
                                  cy="38.3457"
                                  r="1.42021"
                                  transform="rotate(180 13.9177 38.3457)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="13.9177"
                                  cy="13.634"
                                  r="1.42021"
                                  transform="rotate(180 13.9177 13.634)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="13.9177"
                                  cy="50.2754"
                                  r="1.42021"
                                  transform="rotate(180 13.9177 50.2754)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="13.9177"
                                  cy="26.1319"
                                  r="1.42021"
                                  transform="rotate(180 13.9177 26.1319)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="13.9177"
                                  cy="1.42019"
                                  r="1.42021"
                                  transform="rotate(180 13.9177 1.42019)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="1.41963"
                                  cy="87.4849"
                                  r="1.42021"
                                  transform="rotate(180 1.41963 87.4849)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="1.41963"
                                  cy="74.9871"
                                  r="1.42021"
                                  transform="rotate(180 1.41963 74.9871)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="1.41963"
                                  cy="62.4892"
                                  r="1.42021"
                                  transform="rotate(180 1.41963 62.4892)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="1.41963"
                                  cy="38.3457"
                                  r="1.42021"
                                  transform="rotate(180 1.41963 38.3457)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="1.41963"
                                  cy="13.634"
                                  r="1.42021"
                                  transform="rotate(180 1.41963 13.634)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="1.41963"
                                  cy="50.2754"
                                  r="1.42021"
                                  transform="rotate(180 1.41963 50.2754)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="1.41963"
                                  cy="26.1319"
                                  r="1.42021"
                                  transform="rotate(180 1.41963 26.1319)"
                                  fill="#301250"
                                />
                                <circle
                                  cx="1.41963"
                                  cy="1.4202"
                                  r="1.42021"
                                  transform="rotate(180 1.41963 1.4202)"
                                  fill="#301250"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* <button
                        className="block w-full text-blue-800 text-sm font-semibold rounded-lg hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs p-3 my-4">Show
                        Full Information</button> */}
            </div>
            {/* Customer holdings */}
            <div className="my-4"></div>
            {/* Sub Accounts */}
            <div className="bg-white p-3 shadow-sm rounded-sm">
              <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                <span className="border-purple-950">
                  <svg
                    className="h-5 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </span>
                <span className="tracking-wide">Sub Accounts</span>
              </div>

              <div className="text-gray-700 ">
                {accountObj.map((account: any, index: number) => {
                  return (
                    <div className="w-full p-2 mx-auto bg-gray-100 rounded">
                      <Disclosure defaultOpen={index === 0}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-purple-900 bg-purple-100 rounded hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                              <div className="d-flex justify-content-between">
                                <span style={{ paddingRight: '80px' }}>Account ID: {account?.accountId}</span>
                                <span className="px-5">{account?.kyc?.gstin?.value ? `GSTIN: ${account?.kyc?.gstin?.value}` : ""}</span>
                              </div>

                              {account.isPrimary ? (
                                <span className="ml-auto">
                                  <span
                                    className={`bg-blue-500  py-1 px-2 rounded text-white text-sm uppercase mr-5`}
                                  >
                                    Primary
                                  </span>
                                </span>
                              ) : (
                                ""
                              )}

                              <ChevronUpIcon
                                className={`${open ? "transform rotate-180" : ""
                                  } w-5 h-5 text-purple-500`}
                              />
                            </Disclosure.Button>

                            <Disclosure.Panel className="text-sm text-gray-500">
                              <div className="flex flex-wrap justify-center -mx-4 mt-5">
                                {/* {
                                                                metalHoldings.map((metal: any, index: number) => {
                                                                    return (
                                                                        <div className="w-full md:w-1/2 lg:w-1/4 px-4">
                                                                            <div
                                                                                className="bg-white rounded-xl relative z-10 overflow-hidden border border-primary border-opacity-20 shadow-pricing py-10 px-8 sm:p-12 lg:py-10 lg:px-6 xl:p-12 mb-2">
                                        <span className="text-primary font-semibold text-lg block mb-4">
                                        {metal.description}
                                        </span>
                                                                                <h2 className="font-bold text-dark text-[42px]">
                                                                                    {metal.summary.length > 0 ? metal.summary[0].metalBalance : 0}
                                                                                    <span
                                                                                        className="text-base text-body-color font-medium">
                                            / {metal.uom}
                                            </span>
                                                                                </h2>


                                                                                <div>
                                            <span className="absolute right-0 top-7 z-[-1]">
                                              <svg
                                                  width="77"
                                                  height="172"
                                                  viewBox="0 0 77 172"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                              >
                                                  <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)"/>
                                                  <defs>
                                                    <linearGradient
                                                        id="paint0_linear"
                                                        x1="86"
                                                        y1="0"
                                                        x2="86"
                                                        y2="172"
                                                        gradientUnits="userSpaceOnUse"
                                                    >
                                                        <stop stopColor="#301250" stopOpacity="0.09"/>
                                                        <stop
                                                            offset="1"
                                                            stopColor="#C4C4C4"
                                                            stopOpacity="0"
                                                        />
                                                    </linearGradient>
                                                  </defs>
                                              </svg>
                                            </span>
                                                                                    <span
                                                                                        className="absolute right-4 top-4 z-[-1]">
                                              <svg
                                                  width="41"
                                                  height="89"
                                                  viewBox="0 0 41 89"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                              >
                                                  <circle
                                                      cx="38.9138"
                                                      cy="87.4849"
                                                      r="1.42021"
                                                      transform="rotate(180 38.9138 87.4849)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="38.9138"
                                                      cy="74.9871"
                                                      r="1.42021"
                                                      transform="rotate(180 38.9138 74.9871)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="38.9138"
                                                      cy="62.4892"
                                                      r="1.42021"
                                                      transform="rotate(180 38.9138 62.4892)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="38.9138"
                                                      cy="38.3457"
                                                      r="1.42021"
                                                      transform="rotate(180 38.9138 38.3457)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="38.9138"
                                                      cy="13.634"
                                                      r="1.42021"
                                                      transform="rotate(180 38.9138 13.634)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="38.9138"
                                                      cy="50.2754"
                                                      r="1.42021"
                                                      transform="rotate(180 38.9138 50.2754)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="38.9138"
                                                      cy="26.1319"
                                                      r="1.42021"
                                                      transform="rotate(180 38.9138 26.1319)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="38.9138"
                                                      cy="1.42021"
                                                      r="1.42021"
                                                      transform="rotate(180 38.9138 1.42021)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="26.4157"
                                                      cy="87.4849"
                                                      r="1.42021"
                                                      transform="rotate(180 26.4157 87.4849)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="26.4157"
                                                      cy="74.9871"
                                                      r="1.42021"
                                                      transform="rotate(180 26.4157 74.9871)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="26.4157"
                                                      cy="62.4892"
                                                      r="1.42021"
                                                      transform="rotate(180 26.4157 62.4892)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="26.4157"
                                                      cy="38.3457"
                                                      r="1.42021"
                                                      transform="rotate(180 26.4157 38.3457)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="26.4157"
                                                      cy="13.634"
                                                      r="1.42021"
                                                      transform="rotate(180 26.4157 13.634)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="26.4157"
                                                      cy="50.2754"
                                                      r="1.42021"
                                                      transform="rotate(180 26.4157 50.2754)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="26.4157"
                                                      cy="26.1319"
                                                      r="1.42021"
                                                      transform="rotate(180 26.4157 26.1319)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="26.4157"
                                                      cy="1.4202"
                                                      r="1.42021"
                                                      transform="rotate(180 26.4157 1.4202)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="13.9177"
                                                      cy="87.4849"
                                                      r="1.42021"
                                                      transform="rotate(180 13.9177 87.4849)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="13.9177"
                                                      cy="74.9871"
                                                      r="1.42021"
                                                      transform="rotate(180 13.9177 74.9871)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="13.9177"
                                                      cy="62.4892"
                                                      r="1.42021"
                                                      transform="rotate(180 13.9177 62.4892)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="13.9177"
                                                      cy="38.3457"
                                                      r="1.42021"
                                                      transform="rotate(180 13.9177 38.3457)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="13.9177"
                                                      cy="13.634"
                                                      r="1.42021"
                                                      transform="rotate(180 13.9177 13.634)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="13.9177"
                                                      cy="50.2754"
                                                      r="1.42021"
                                                      transform="rotate(180 13.9177 50.2754)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="13.9177"
                                                      cy="26.1319"
                                                      r="1.42021"
                                                      transform="rotate(180 13.9177 26.1319)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="13.9177"
                                                      cy="1.42019"
                                                      r="1.42021"
                                                      transform="rotate(180 13.9177 1.42019)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="1.41963"
                                                      cy="87.4849"
                                                      r="1.42021"
                                                      transform="rotate(180 1.41963 87.4849)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="1.41963"
                                                      cy="74.9871"
                                                      r="1.42021"
                                                      transform="rotate(180 1.41963 74.9871)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="1.41963"
                                                      cy="62.4892"
                                                      r="1.42021"
                                                      transform="rotate(180 1.41963 62.4892)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="1.41963"
                                                      cy="38.3457"
                                                      r="1.42021"
                                                      transform="rotate(180 1.41963 38.3457)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="1.41963"
                                                      cy="13.634"
                                                      r="1.42021"
                                                      transform="rotate(180 1.41963 13.634)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="1.41963"
                                                      cy="50.2754"
                                                      r="1.42021"
                                                      transform="rotate(180 1.41963 50.2754)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="1.41963"
                                                      cy="26.1319"
                                                      r="1.42021"
                                                      transform="rotate(180 1.41963 26.1319)"
                                                      fill="#301250"
                                                  />
                                                  <circle
                                                      cx="1.41963"
                                                      cy="1.4202"
                                                      r="1.42021"
                                                      transform="rotate(180 1.41963 1.4202)"
                                                      fill="#301250"
                                                  />
                                              </svg>
                                            </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>)
                                                                })
                                                            } */}
                              </div>

                              <div className="pt-4 pb-2 grid md:grid-cols-2">
                                <div className="grid grid-cols-2">
                                  <div className="px-4 py-2 font-semibold">
                                    Name
                                  </div>
                                  <div className="px-4 py-2">
                                    {account.displayName || "N/A"}
                                  </div>
                                </div>
                                <div className="grid grid-cols-2">
                                  <div className="px-4 py-2 font-semibold">
                                    Account Status
                                  </div>
                                  <div className="py-2">
                                    <span className="ml-auto">
                                      <span
                                        className={`${account.active === "ENABLED"
                                          ? "bg-green-500"
                                          : "bg-red-500"
                                          }  py-1 px-2 rounded text-white text-sm`}
                                      >
                                        {account.active}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <div className="grid grid-cols-1 m-1">
                                  <fieldset className="bg-white rounded mt-3">
                                    <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mt-1">
                                      Billing Address
                                    </legend>
                                    {"IND" !== customerObj.customerType &&
                                      "UNREGISTERED" ===
                                      customerObj.customerCategory &&
                                      "" === account.billingAddress.address1 ? (
                                      <div className="flex items-center h-full m-10">
                                        <div className="m-auto">
                                          <button
                                            onClick={() => {
                                              editModalElements(
                                                account,
                                                "BILLING"
                                              );
                                            }}
                                            className="border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold px-3 py-1 text-xs outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                          >
                                            + add new address
                                          </button>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <div className="grid grid-cols-2">
                                          <div className="px-4 py-2 font-semibold">
                                            Address
                                          </div>
                                          <div className="px-4 py-2 uppercase">
                                            {account.billingAddress.address1}{" "}
                                            {account.billingAddress.address2}
                                          </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                          <div className="px-4 py-2 font-semibold">
                                            City
                                          </div>
                                          <div className="px-4 py-2 uppercase">
                                            {account.billingAddress.city}
                                          </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                          <div className="px-4 py-2 font-semibold">
                                            State
                                          </div>
                                          <div className="px-4 py-2 uppercase">
                                            {account.billingAddress.state}
                                          </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                          <div className="px-4 py-2 font-semibold">
                                            Country Code
                                          </div>
                                          <div className="px-4 py-2 uppercase">
                                            {account.billingAddress.countryCode}
                                          </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                          <div className="px-4 py-2 font-semibold">
                                            Pin Code
                                          </div>
                                          <div className="px-4 py-2 uppercase">
                                            {account.billingAddress.pinCode}
                                          </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                          <div className="px-4 py-2 font-semibold">
                                            Status
                                          </div>
                                          <div className="px-4 py-2">
                                            <span className="ml-auto">
                                              <span
                                                className={`${account.billingAddress
                                                  .active === "ENABLED"
                                                  ? "bg-green-500"
                                                  : "bg-red-500"
                                                  }  py-1 px-2 rounded text-white text-sm`}
                                              >
                                                {account.billingAddress.active}
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </fieldset>
                                </div>
                                <div className="grid grid-cols-1  m-1">
                                  <fieldset className="bg-white rounded mt-3">
                                    <legend className="font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mt-1">
                                      Shipping Address
                                    </legend>
                                    {"IND" !== customerObj.customerType &&
                                      "UNREGISTERED" ===
                                      customerObj.customerCategory &&
                                      "" === account.shippingAddress.address1 ? (
                                      <div className="flex items-center h-full  m-10">
                                        <div className="m-auto">
                                          <button
                                            onClick={() => {
                                              editModalElements(
                                                account,
                                                "SHIPPING"
                                              );
                                            }}
                                            className="border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold px-3 py-1 text-xs outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                          >
                                            + add new address
                                          </button>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <div className="grid grid-cols-2">
                                          <div className="px-4 py-2 font-semibold">
                                            Address
                                          </div>
                                          <div className="px-4 py-2 uppercase">
                                            {account.shippingAddress.address1}{" "}
                                            {account.shippingAddress.address2}
                                          </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                          <div className="px-4 py-2 font-semibold">
                                            City
                                          </div>
                                          <div className="px-4 py-2 uppercase">
                                            {account.shippingAddress.city}
                                          </div>
                                        </div>

                                        <div className="grid grid-cols-2">
                                          <div className="px-4 py-2 font-semibold">
                                            State
                                          </div>
                                          <div className="px-4 py-2 uppercase">
                                            {account.shippingAddress.state}
                                          </div>
                                        </div>

                                        <div className="grid grid-cols-2">
                                          <div className="px-4 py-2 font-semibold">
                                            Country Code
                                          </div>
                                          <div className="px-4 py-2 uppercase">
                                            {
                                              account.shippingAddress
                                                .countryCode
                                            }
                                          </div>
                                        </div>

                                        <div className="grid grid-cols-2">
                                          <div className="px-4 py-2 font-semibold">
                                            Pin Code
                                          </div>
                                          <div className="px-4 py-2 uppercase">
                                            {account.shippingAddress.pinCode}
                                          </div>
                                        </div>

                                        <div className="grid grid-cols-2">
                                          <div className="px-4 py-2 font-semibold">
                                            Status
                                          </div>
                                          <div className="px-4 py-2">
                                            <span className="ml-auto">
                                              <span
                                                className={`${account.shippingAddress
                                                  .active === "ENABLED"
                                                  ? "bg-green-500"
                                                  : "bg-red-500"
                                                  }  py-1 px-2 rounded text-white text-sm`}
                                              >
                                                {account.shippingAddress.active}
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </fieldset>
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  );
                })}
              </div>
              {/* <button
                        className="block w-full text-blue-800 text-sm font-semibold rounded-lg hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs p-3 my-4">Show
                        Full Information</button> */}
            </div>
            {/* Sub Accounts */}
            <div className="my-4"></div>
            {/* Documents */}
            {/* <div className="bg-white p-3 shadow-sm rounded-sm">
              <div className="grid grid-cols-1">
                <div>
                  <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                    <span className="border-purple-950">
                      <svg
                        className="h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                    </span>
                    <span className="tracking-wide">Documents</span>
                  </div>
                  <div className="text-gray-700 divide-y justify-center container px-4 mx-auto max-w-7x1 mb-5">
                   
                    <div className="flex flex-wrap -m-4">
                      {docs.map((doc: any, index: number) => {
                        return (
                          <div className="xl:w-1/3 md:w-1/2 p-2">
                            <fieldset className={`bg-gray-100  rounded`}>
                              <legend className="text-left font-bold px-2 bg-black text-white text-xs py-1 rounded ml-3 mt-1">
                                {doc.fileType}
                              </legend>
                              {Object.keys(doc?.file).length !== 0 ? (
                                <div
                                  className={`text-gray-900 bg-gray-100 p-3 rounded-lg grid grid-cols-2`}
                                >
                                  <a
                                    onClick={() => {
                                      doc.fileType === "IMG"
                                        ? imageOpen(doc)
                                        : window.open(
                                          process.env.REACT_APP_URL +
                                          "/" +
                                          doc.file.resource
                                        );
                                    }}
                                  >
                                    <img
                                      className="xl:w-3/4 h-25 w-1/4 rounded object-cover object-center"
                                      src={file}
                                      alt="Image Size 720x400"
                                    ></img>
                                  </a>
                                  <div className="text-left grid grid-cols-1">
                                    <h4 className="text-sm text-gray-900">
                                      {doc.title}
                                    </h4>
                                    <div
                                      className={` leading-relaxed text-xs text-gray-900`}
                                    >
                                      {doc.description}
                                    </div>
                                    <div>
                                      <span
                                        style={{ fontSize: "12px" }}
                                        className="float-left"
                                      >
                                        Required
                                      </span>
                                      <span
                                        style={{ fontSize: "10px" }}
                                        className={`float-right mb-1 text-truncate uppercase bg-blue-400 py-0.5 px-2 rounded text-white text-xs `}
                                      >
                                        {doc.optional ? "No" : "Yes"}
                                      </span>
                                    </div>
                                    <div>
                                      <span
                                        style={{ fontSize: "12px" }}
                                        className="float-left"
                                      >
                                        Default Verified
                                      </span>
                                      <span
                                        style={{ fontSize: "10px" }}
                                        className={`float-right text-truncate uppercase bg-blue-400 py-0.5 px-2 rounded text-white text-xs `}
                                      >
                                        {doc.defaultVerified ? "Yes" : "No"}
                                      </span>
                                    </div>
                                    {doc.defaultVerified ||
                                      (doc.file && doc.file.isVerified) ? (
                                      <span className="mt-1">
                                        <span
                                          className={`bg-green-500 py-1 px-2 rounded text-white text-xs`}
                                        >
                                          VERIFIED
                                        </span>
                                      </span>
                                    ) : (
                                      <button
                                        className="text-xs bg-transparent hover:bg-blue-300 text-blue-500 font-semibold hover:text-blue-700 px-2 border border-blue-200 hover:border-transparent rounded"
                                        onClick={() =>
                                          openModal(doc.file.docUploadId)
                                        }
                                      >
                                        Verify
                                      </button>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className={`${doc.uploaded
                                    ? "hover:text-purple-900 hover:bg-purple-100"
                                    : ""
                                    } text-gray-900 bg-gray-100 p-3 rounded-lg grid grid-cols-2`}
                                >
                                  <div className="h-24 relative overflow-hidden">
                                    <img
                                      className="xl:w-3/4 h-25 w-1/4 rounded object-cover object-center"
                                      src={file}
                                      alt="Image Size 720x400"
                                    ></img>
                                    <div className="xl:w-3/4 absolute py-1.5 bottom-5 inset-x-0 bg-red-100 text-red-900 text-xs text-center leading-4">
                                      NOT UPLOADED
                                    </div>
                                  </div>
                                 
                                  <div className="text-left grid grid-cols-1">
                                    <h4 className="text-sm text-gray-900">
                                      {doc.title}
                                    </h4>
                                    <div
                                      className={` leading-relaxed text-xs text-gray-900`}
                                    >
                                      {doc.description}
                                    </div>
                                    <div>
                                      <span
                                        style={{ fontSize: "12px" }}
                                        className="float-left"
                                      >
                                        Required
                                      </span>
                                      <span
                                        style={{ fontSize: "10px" }}
                                        className={`float-right mb-1 text-truncate uppercase bg-blue-400 py-0.5 px-2 rounded text-white text-xs `}
                                      >
                                        {doc.optional ? "No" : "Yes"}
                                      </span>
                                    </div>
                                    <div>
                                      <span
                                        style={{ fontSize: "12px" }}
                                        className="float-left"
                                      >
                                        Default Verified
                                      </span>
                                      <span
                                        style={{ fontSize: "10px" }}
                                        className={`float-right text-truncate uppercase bg-blue-400 py-0.5 px-2 rounded text-white text-xs `}
                                      >
                                        {doc.defaultVerified ? "Yes" : "No"}
                                      </span>
                                    </div>
                                    <div>
                                      <button
                                        className="bg-transparent border border-blue-200 font-semibold grid hover:bg-blue-300 hover:border-transparent hover:text-blue-700 mt-1.5 p-0.5 px-2 rounded text-blue-500 text-xs w-full"
                                        onClick={() => fileChanged(doc, index)}
                                      >
                                        Upload
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </fieldset>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* Documents */}

            <div className="my-4">
              {realisedLoading ?
                (
                  <span style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>Loading...</span>
                ) :
                (
                  <GainLossSummeryTable realised={realisedData} unRealised={unRealisedData} />

                )
              }
            </div>
          </div>
        </div>
      </div>

      <Modal
        size={""}
        show={show}
        handleClose={hideModal}
        children={children}
      />
      <Modal
        size={""}
        show={uploadModalShow}
        handleClose={hideModal}
        children={children}
      />
      <Modal
        size={""}
        show={otpModalOpen}
        handleClose={() => {
          setOtpModalOpen(false);
        }}
        children={otpChildren}
      />

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => null}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 opacity-50  bg-black " />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Please Confirm!
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to verify this document?
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => {
                      closeModal();
                      set_verification(docId);
                    }}
                  >
                    Yes, please!
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center ml-2 px-4 py-2 text-sm font-medium text-blue-900 bg-transparent border border-blue-100 rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}
                  >
                    No
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      {openBankModal ? (
        <EditbankModal
          onCloseModal={closeBankModal}
          bankDetails={customerObj?.bankDetails}
          customer={customerObj}
          get_customer={get_customer}
        />
      ) : null}
    </Layout>
  );
};

export default SingleUSer;
